import React, { useState } from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import IntersectionVisible from 'react-intersection-visible';

import { LaunchIcon, UsermanualIcon, BrochureIcon, EmailIcon } from 'assets/img';
import { StyledContainer, StyledActionBox, StyledActionButton } from './StyledMoreInfo';
const MoreInfo = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [scrollToThisElement, setScrollToThisElement] = useState(false);
  const onShow = (e) => {
    if (e && !scrollToThisElement) {
      setScrollToThisElement(true);
    }
  };
  const userManual = () => {
    switch (language) {
      case 'en': {
        return process.env.GATSBY_USER_MANUAL_EN;
      }
      case 'tw': {
        return process.env.GATSBY_USER_MANUAL_TW;
      }
      case 'cn': {
        return process.env.GATSBY_USER_MANUAL_CN;
      }
      default: {
        return process.env.GATSBY_USER_MANUAL_EN;
      }
    }
  };
  return (
    <IntersectionVisible onShow={onShow} options={{ rootMargin: '-20%' }}>
      <StyledContainer scrollToThisElement={scrollToThisElement}>
        <StyledActionBox hiddenInPhone>
          <StyledActionButton href={`${process.env.GATSBY_LAUNCH}`} target='launch'>
            <LaunchIcon />
            <span>{t('moreInfo.launch')}</span>
          </StyledActionButton>
          <StyledActionButton href={userManual()} target='userManual'>
            <UsermanualIcon />
            <span>{t('moreInfo.userManual')}</span>
          </StyledActionButton>
        </StyledActionBox>
        <StyledActionBox>
          <StyledActionButton href={`${process.env.GATSBY_BROCHURE}`} target='brochure'>
            <BrochureIcon />
            <span>{t('moreInfo.brochure')}</span>
          </StyledActionButton>
          <StyledActionButton href={`mailto:${process.env.GATSBY_CONTACT_US}`}>
            <EmailIcon />
            <span>{t('moreInfo.contactUs')}</span>
          </StyledActionButton>
        </StyledActionBox>
      </StyledContainer>
    </IntersectionVisible>
  );
};

export default MoreInfo;
