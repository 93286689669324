import React, { useContext, useState } from 'react';
import { ContextStore } from 'store/ContextStore';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { overviewPc, overviewPhone } from 'assets/img';
import IntersectionVisible from 'react-intersection-visible';
import StyledTitle from 'components/StyledTitle';
import { StyledContainer, StyledIntroduction } from './StyledOverview';

const Overview = () => {
  const { t } = useTranslation();
  const { state } = useContext(ContextStore);
  const [scrollToThisElement, setScrollToThisElement] = useState(false);
  const { phoneSize } = state;
  const onShow = (e) => {
    if (e && !scrollToThisElement) {
      setScrollToThisElement(true);
    }
  };
  return (
    <IntersectionVisible onShow={onShow} options={{ rootMargin: '-20%' }}>
      <StyledContainer name='overview' scrolltothiselement={scrollToThisElement.toString()}>
        <StyledTitle scrollToThisElement={scrollToThisElement}>{t('overview.title')}</StyledTitle>
        <StyledIntroduction scrollToThisElement={scrollToThisElement}>
          <p>{t('overview.fisrtContent')}</p>
          <br />
          <p>{t('overview.secondContent')}</p>
        </StyledIntroduction>
        <img src={phoneSize ? overviewPhone : overviewPc} alt='' />
      </StyledContainer>
    </IntersectionVisible>
  );
};

export default Overview;
