import styled from 'styled-components';
import { bannerBg } from 'assets/img';
import { padWidth, phoneWidth } from 'utility/rwd';
import { fadeInBottomAnimation } from 'utility/animation';

export const StyledCotainer = styled.div`
  width: 100%;
  height: 641px;
  background: url(${bannerBg});
  background-size: cover;
  background-position: center;
  > img {
    display: none;
  }
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    margin-top: 70px;
  }
  @media (max-width: ${padWidth}) {
    background-position: 34%;
    height: 692px;
    > img {
      display: block;
      width: 100%;
      max-width: 400px;
      margin-top: 40px;
      visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
      animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.8s') : 'none')};
    }
    &::before {
      margin-top: 69px;
    }
  }
`;

export const StlyedLogo = styled.div`
  padding-left: 10.5%;
  padding-top: 151px;
  color: ${(props) => props.theme.bannerColor};
  position: relative;
  > img {
    width: 100%;
    max-width: 438px;
    visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
    animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation() : 'none')};
  }
  h3 {
    font-size: 36px;
    margin-top: 20px;
    visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
    animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.6s') : 'none')};
    &::after {
      content: '';
      display: block;
      margin: 21px 0;
      width: 64px;
      height: 2px;
      background: ${(props) => props.theme.blue};
    }
  }
  h4 {
    font-size: 28px;
    line-height: 1.2;
    max-width: ${(props) => (props.language === 'en' ? '310px' : '279px')};
    position: relative;
    visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
    animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.7s') : 'none')};
    img {
      visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
      animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.8s') : 'none')};
      max-width: 400px;
      position: absolute;
      top: 17px;
      left: 230px;
    }
  }
  @media (max-width: ${padWidth}) {
    padding-left: 4%;
    padding-top: 60px;
    h3 {
      line-height: 1.2;
      &::after {
        width: 96px;
        height: 3px;
      }
    }
    h4 {
      img {
        display: none;
      }
    }
  }
  @media (max-width: ${phoneWidth}) {
    > img {
      width: 95%;
    }
  }
`;
