import { createContext } from 'react';
const initialState = {
  phoneSize: false,
  scrollY: '',
};
const ContextStore = createContext({
  state: {
    phoneSize: false,
    scrollY: '',
  },
});

export { initialState, ContextStore };
