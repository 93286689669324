import styled from 'styled-components';
import { padWidth } from 'utility/rwd';
import { fadeInBottomAnimation } from 'utility/animation';
import { Element } from 'react-scroll';

export const StyledContainer = styled(Element)`
  padding: 100px 11%;
  background: ${(props) => props.theme.backgroundGary};
  img {
    width: 100%;
    margin-top: 45px;
    visibility: ${(props) => (props.scrolltothiselement === 'true' ? 'visible' : 'hidden')};
    animation: ${(props) => (props.scrolltothiselement === 'true' ? fadeInBottomAnimation('0.5s') : 'none')};
  }
  @media (max-width: ${padWidth}) {
    padding: 80px 4%;
  }
`;

export const StyledIntroduction = styled.div`
  color: ${(props) => props.theme.introductionGary};
  line-height: 1.5;
  font-size: 18px;
  visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
  animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.3s') : 'none')};
`;
