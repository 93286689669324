const languageOptions = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: '繁體中文',
    value: 'tw',
  },
  {
    label: '简体中文',
    value: 'cn',
  },
];
export const getLang = (language) => languageOptions.find((languageOption) => languageOption.value === language);
export default languageOptions;
