import styled from 'styled-components';
import { fadeInBottomAnimation } from 'utility/animation';
import { padWidth, phoneWidth } from 'utility/rwd';
const actionRwdWitdh = '1042px';
export const StyledContainer = styled.div`
  padding: 22px 11%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${(props) => props.theme.introductionGary};
  visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
  animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.3s') : 'none')};
  @media (max-width: ${padWidth}) {
    padding: 35px 4%;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;

// export const StyledActionContainer = styled.div`
//   color: ${(props) => props.theme.introductionGary};
//   display: flex;
//   justify-content: space-around;
//   flex-wrap: wrap;
//   visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
//   animation: ${(props) => (props.scrollToThisElement ? fadeInXwayAnimation('right') : 'none')};
//   @media (max-width: ${padWidth}) {
//     justify-content: center;
//     flex-wrap: nowrap;
//     width: 100%;
//     animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.3s') : 'none')};
//   }
//   @media (max-width: ${phoneWidth}) {
//     flex-wrap: wrap;
//   }
// `;
export const StyledActionBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  @media (max-width: ${actionRwdWitdh}) {
    width: 100%;
  }
  @media (max-width: ${padWidth}) {
    display: ${(props) => (props.hiddenInPhone ? 'none' : 'flex')};
  }
`;
export const StyledActionButton = styled.a`
  background: ${(props) => props.theme.actionGary};
  color: ${(props) => props.theme.introductionGary};
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 9px 0 9px 16px;
  cursor: pointer;
  font-weight: bold;
  min-height: 49px;
  width: 196px;
  margin-bottom: 10px;
  white-space: nowrap;
  text-decoration: none;
  svg {
    width: 42px;
    height: 42px;
    margin-right: 13px;
    color: ${(props) => props.theme.blue};
  }
  &:hover {
    background: ${(props) => props.theme.actionHoverGary};
    color: ${(props) => props.theme.blue};
  }
  @media (max-width: ${actionRwdWitdh}) {
    width: 49%;
    margin: 0 1% 10px;
  }
  @media (max-width: ${phoneWidth}) {
    margin: 0 1%;
    padding: 1% 5%;
    svg {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
`;
