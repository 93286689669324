import React from 'react';
import { StlyedLi } from './StyledNavItem';
const NavItem = ({ title, onClick, text, className }) => {
  return (
    <StlyedLi
      activeClass='isactive'
      onClick={onClick}
      to={title}
      smooth={true}
      isDynamic={true}
      duration={500}
      spy={true}
      offset={-87}
      className={className}
    >
      {text}
    </StlyedLi>
  );
};

export default NavItem;
