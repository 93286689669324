import React, { useState, useContext } from 'react';
import { ContextStore } from 'store/ContextStore';
import { useI18next } from 'gatsby-plugin-react-i18next';
import languageOptions, { getLang } from './LangOptions';
import { GlobeIcon, Triangle } from 'assets/img';

import { StlyedContainer, StyledTitle, StyledUl, StyledTriangle } from './StyledLangSelector';

const LangSelector = () => {
  const { language, changeLanguage } = useI18next();
  const { state } = useContext(ContextStore);
  const { phoneSize } = state;
  const [open, setOpen] = useState(false);
  const lang = getLang(language);

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };
  const handleMouseEnter = () => {
    if (!phoneSize) {
      setOpen(true);
    }
  };
  const handleMouseLeave = () => {
    if (!phoneSize) {
      setOpen(false);
    }
  };
  const handleLang = (e, language) => {
    e.preventDefault();
    changeLanguage(language.value);
  };
  const renderLang = languageOptions
    .filter((language) => (phoneSize ? true : language.value !== lang.value))
    .map((language) => (
      <li key={language.value}>
        <button onClick={(e) => handleLang(e, language)} onMouseEnter={handleMouseEnter}>
          {language.label}
        </button>
      </li>
    ));
  return (
    <StlyedContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <StyledTitle onClick={handleToggle} open={open}>
        <GlobeIcon />
        <div>
          <span>{phoneSize ? 'Language' : lang.label}</span>
          <StyledTriangle open={open}>
            <Triangle />
          </StyledTriangle>
        </div>
      </StyledTitle>
      <StyledUl open={open}>{renderLang}</StyledUl>
    </StlyedContainer>
  );
};

export default LangSelector;
