import styled from 'styled-components';
import { fadeInXwayAnimation, fadeInBottomAnimation } from 'utility/animation';
import { padWidth } from 'utility/rwd';
import { Element } from 'react-scroll';

export const StyledContainer = styled(Element)`
  padding: 100px 11%;
  text-align: center;

  @media (max-width: ${padWidth}) {
    padding: 80px 4%;
  }
`;
export const StyledIntroduction = styled.div`
  color: ${(props) => props.theme.introductionGary};
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 30px;
  visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
  animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.1s') : 'none')};
`;

export const StyledPricingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media (max-width: ${padWidth}) {
    display: block;
  }
`;
export const StyledPricingBox = styled.div`
  width: 48%;
  visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
  animation: ${(props) => (props.scrollToThisElement ? fadeInXwayAnimation(props.xWay, '0.2s') : 'none')};
  @media (max-width: ${padWidth}) {
    width: 100%;
    margin-bottom: 30px;
    animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation('0.2s') : 'none')};
  }
`;
export const StyledPricingTitle = styled.div`
  color: ${(props) => props.theme.pricingYearGary};
  background: ${(props) => props.theme.pricingYearBgGary};
  font-size: 18px;
  padding-bottom: 17px;
  padding-top: 91px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;
  > p {
    margin-top: 26px;
    font-weight: bold;
  }
`;
export const StyledPricingDivider = styled.div`
  height: 10px;
  background: ${(props) => (props.first ? props.theme.orange : props.theme.blue)};
  margin-top: 2px;
`;
export const StyledPricingContent = styled.div`
  background: ${(props) => props.theme.pricingCotentGary};
  padding: 6% 9%;
  color: ${(props) => props.theme.introductionGary};
  line-height: 1.5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
export const StyledPricingImg = styled.img`
  margin-top: 24px;
  width: ${(props) => (props.first ? '114px' : '105px')};
`;
export const StyledPricing = styled.div`
  font-family: Raleway, 'Open Sans', sans-serif, -apple-system, 'Microsoft JhengHei';
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 59px;
  span {
    font-size: 29px;
  }
  p {
    font-size: 97px;
    line-height: 0.75;
  }
`;
