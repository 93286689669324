import styled from 'styled-components';
import { padWidth } from 'utility/rwd';
import { Link } from 'react-scroll';

export const StlyedLi = styled(Link)`
  padding: 0 9px;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  &:hover {
    color: white;
  }
  &:first-of-type {
    display: none;
  }
  &::after {
    backface-visibility: hidden;
    height: 3px;
    bottom: -26px;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
  }
  &.isactive {
    color: white;
    &::after {
      height: 3px;
      background: ${(props) => props.theme.sidebarOrange};
      backface-visibility: hidden;
      transition: width 0.3s ease;
      width: 100%;
    }
  }
  @media (max-width: ${padWidth}) {
    color: ${(props) => (props.isactive === 'true' ? props.theme.sidebarOrange : '')};
    padding: 6% 15%;
    &:hover {
      color: ${(props) => props.theme.headerNavColorHover};
    }
    &.isactive {
      color: ${(props) => props.theme.sidebarOrange};
    }
    &::after {
      display: none;
    }
    &:first-of-type {
      display: inline-block;
    }
  }
`;
