import styled from 'styled-components';
import { fadeInBottomAnimation } from 'utility/animation';

export const StyledTitle = styled.h2`
  color: ${(props) => props.theme.titleGary};
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.19;
  visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
  animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation() : 'none')};
  &::after {
    content: '';
    display: block;
    width: 64px;
    height: 2px;
    background: ${(props) => props.theme.blue};
    margin: 30px auto;
  }
`;
