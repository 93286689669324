import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { feature } from 'assets/img';
import StyledTitle from 'components/StyledTitle';
import IntersectionVisible from 'react-intersection-visible';
import {
  StyledContainer,
  StyledIntroductionContainer,
  StyledIntroductionBox,
  StyledIntroduction,
} from './StyledFeatures';

const Features = () => {
  const { t } = useTranslation();
  const [scrollToThisElement, setScrollToThisElement] = useState(false);
  const onShow = (e) => {
    if (e && !scrollToThisElement) {
      setScrollToThisElement(true);
    }
  };
  return (
    <IntersectionVisible onShow={onShow} options={{ rootMargin: '-20%' }}>
      <StyledContainer name='features'>
        <StyledTitle scrollToThisElement={scrollToThisElement}>{t('features.title')}</StyledTitle>
        <StyledIntroductionContainer>
          <StyledIntroductionBox scrollToThisElement={scrollToThisElement}>
            <StyledIntroduction scrollToThisElement={scrollToThisElement}>
              <li>
                <h5>{t('features.industrialTitle')}</h5>
                <p>{t('features.industrialFirstConetent')}</p>
                <br />
                <p>{t('features.industrialSecondConetent')}</p>
              </li>
            </StyledIntroduction>
            <StyledIntroduction scrollToThisElement={scrollToThisElement}>
              <li>
                <h5>{t('features.scalableTitle')}</h5>
                <p>{t('features.scalableFirstConetnt')}</p>
                <br />
                <p>{t('features.scalableSecondConetnt')}</p>
              </li>
            </StyledIntroduction>
          </StyledIntroductionBox>
          <StyledIntroductionBox scrollToThisElement={scrollToThisElement}>
            <img src={feature} alt='' />
          </StyledIntroductionBox>
        </StyledIntroductionContainer>
      </StyledContainer>
    </IntersectionVisible>
  );
};

export default Features;
