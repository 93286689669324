import styled from 'styled-components';
import { padWidth } from 'utility/rwd';
import { fadeInXwayAnimation, fadeInBottomAnimation } from 'utility/animation';
import { Element } from 'react-scroll';

export const StyledContainer = styled(Element)`
  padding: 100px 11%;
  @media (max-width: ${padWidth}) {
    padding: 80px 4%;
  }
`;

export const StyledIntroductionContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${padWidth}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledIntroductionBox = styled.div`
  width: 49%;
  img {
    width: 100%;
    visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
    animation: ${(props) => (props.scrollToThisElement ? fadeInXwayAnimation('right') : 'none')};
  }

  @media (max-width: ${padWidth}) {
    width: 100%;
    img {
      animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation() : 'none')};
    }
  }
`;
export const StyledIntroduction = styled.ul`
  font-size: 18px;
  margin-bottom: 38px;
  visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
  animation: ${(props) => (props.scrollToThisElement ? fadeInXwayAnimation('left') : 'none')};
  list-style: none;
  margin-left: 0;
  li{
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      position: absolute;
      top:10px;
      border-radius: 50%;
      left: -12px;
      background: ${(props) => props.theme.blue};
    }
  }
  h5 {
    color: ${(props) => props.theme.blue};
    font-weight: bold;
    margin-bottom: 14px;
    line-height: 1.33;
    /* &:before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: -10px;
      transform: translateY(-50%);
      background: ${(props) => props.theme.blue};
    } */
  }
  p {
    line-height: 1.5;
    color: ${(props) => props.theme.introductionGary};
  }
  @media (max-width: ${padWidth}) {
    animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation() : 'none')};
  }
`;
