import styled from 'styled-components';
import { padWidth } from 'utility/rwd';
export const StyledContainer = styled.footer`
  padding: 40px 11%;
  background: ${(props) => props.theme.blue};
  text-align: center;
  color: white;
  font-size: 18px;
  position: relative;
  @media (max-width: ${padWidth}) {
    padding: 40px 4%;
  }
`;

export const StyledToTop = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.orange};
  cursor: pointer;
  position: absolute;
  top: -30px;
  right: 11%;
  &:hover {
    background: ${(props) => props.theme.orangeHover};
  }
  img {
    width: 30px;
  }
  @media (max-width: ${padWidth}) {
    display: none;
  }
`;
