import React, { useState } from 'react';
import IntersectionVisible from 'react-intersection-visible';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { bannerLogo, bannerPic } from 'assets/img';

import { StyledCotainer, StlyedLogo } from './StyledBanner';
const Banner = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [scrollToThisElement, setScrollToThisElement] = useState(false);
  const onShow = (e) => {
    if (e && !scrollToThisElement) {
      setScrollToThisElement(true);
    }
  };
  return (
    <IntersectionVisible onShow={onShow} options={{ rootMargin: '-20%' }}>
      <StyledCotainer scrollToThisElement={scrollToThisElement}>
        <StlyedLogo language={language} scrollToThisElement={scrollToThisElement}>
          <img src={bannerLogo} alt='' />
          <h3>{t('banner.title')}</h3>
          <h4>
            {t('banner.introduction')}
            <img src={bannerPic} alt='' />
          </h4>
        </StlyedLogo>
        <img src={bannerPic} alt='' />
      </StyledCotainer>
    </IntersectionVisible>
  );
};

export default Banner;
