import styled from 'styled-components';
import { padWidth } from 'utility/rwd';

export const StlyedContainer = styled.div`
  position: relative;
  font-weight: bold;
  cursor: pointer;
  @media (max-width: ${padWidth}) {
    width: 100%;
  }
`;
export const StyledTitle = styled.div`
  padding: 14px 11px;
  border: 2px solid ${(props) => (props.open ? props.theme.headerNavColorHover : props.theme.headerNavColor)};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: ${(props) => (props.open ? '2px solid transparent' : '2px solid white')};
  &:hover {
    color: ${(props) => props.theme.headerNavColorHover};
  }
  > svg {
    width: 24px;
    margin-right: 16px;
    opacity: ${(props) => (props.open ? 1 : 0.7)};
  }
  span {
    min-width: 67px;
    display: inline-block;
  }
  @media (max-width: ${padWidth}) {
    border: none;
    padding: 5% 13%;
    > svg {
      width: 30px;
      margin-right: 20px;
    }
  }
`;
export const StyledTriangle = styled.div`
  /* border-width: 13px 7.5px 0 7.5px; */
  opacity: ${(props) => (props.open ? 1 : 0.7)};
  display: inline-block;
  transform: rotate(180deg);
  svg {
    width: 16px;
    height: 16px;
    fill: white;
  }
  margin-left: 10px;
  @media (max-width: ${padWidth}) {
    display: none;
  }
`;
export const StyledUl = styled.ul`
  position: absolute;
  top: 54px;
  background: ${(props) => props.theme.blue};
  left: 0;
  right: 0;
  border: 2px solid ${(props) => (props.open ? props.theme.headerNavColorHover : props.theme.headerNavColor)};
  border-top: none;
  transition: max-height 0.5s ease;
  height: auto;
  max-height: ${(props) => (props.open ? '82px' : 0)};
  overflow: hidden;
  button {
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 0 0 17px 51px;
    font-weight: bold;
    font-size: 18px;
    color: ${(props) => props.theme.headerNavColor};
    outline: none;
    border: none;
    background: transparent;
    &:hover {
      color: white;
    }
  }
  @media (max-width: ${padWidth}) {
    border: none;
    background: none;
    padding: 0;
    max-height: ${(props) => (props.open ? '179px' : 0)};
    position: relative;
    top: 0;
    li {
      background: ${(props) => props.theme.sidebarLanguageItemBlue};
      padding: 5% 13%;
      margin-bottom: 4px;
      &:hover {
        background: ${(props) => props.theme.sidebarLanguageItemHoverBlue};
      }
      &::before {
        display: inline-block;
        content: '';
        width: 30px;
        margin-right: 20px;
      }
    }
  }
`;
