import React from 'react';
import { animateScroll } from 'react-scroll';
import { topIcon } from 'assets/img';
import { StyledContainer, StyledToTop } from './StyledFooter';
const Footer = () => {
  const handleScrollToTop = () => {
    animateScroll.scrollToTop();
  };
  return (
    <StyledContainer>
      <p>Copyright ©2020 ORing Industrial Networking Corp. All Rights Reserved.</p>
      <StyledToTop onClick={handleScrollToTop}>
        <img src={topIcon} alt='' />
      </StyledToTop>
    </StyledContainer>
  );
};

export default Footer;
