import styled from 'styled-components';
import { padWidth } from 'utility/rwd';

export const StyledLogo = styled.h1`
  cursor: pointer;
  img {
    width: 113px;
  }
  @media (max-width: ${padWidth}) {
    img {
      width: 161px;
    }
  }
`;
export const StyledHeader = styled.header`
  background: ${(props) => props.theme.blue};
  padding: 7px 11%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  > svg {
    display: none;
  }
  @media (max-width: ${padWidth}) {
    height: 55px;
    padding: 7px 4%;
    font-size: 22px;
    > svg {
      width: 32px;
      cursor: pointer;
      opacity: ${(props) => (props.sidebarOpen ? 0 : 1)};
      transition: opacity 0.5s ease;
      display: block;
    }
  }
`;

export const StyledNav = styled.nav`
  color: ${(props) => props.theme.headerNavColor};
  display: flex;
  align-items: center;
  white-space: nowrap;
  @media (max-width: ${padWidth}) {
    position: fixed;
    z-index: 10;
    right: ${(props) => (props.sidebarOpen ? 0 : '-379px')};
    top: 0;
    bottom: 0;
    height: 100vh;
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
    background: ${(props) => props.theme.sidebarBlue};
    opacity: 0.9;
    width: 78%;
    max-width: 379px;
    transition: right 0.5s ease;
  }
`;
export const StlyedUl = styled.ul`
  display: flex;
  position: relative;
  margin-right: 9px;
  &::after {
    content: '';
    height: 5px;
    background: ${(props) => props.theme.orange};
    display: block;
    position: absolute;
    bottom: -26px;
  }
  @media (max-width: ${padWidth}) {
    margin-right: 0;
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledClose = styled.div`
  display: none;
  @media (max-width: ${padWidth}) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 32px;
    margin: 24px 0;
    svg {
      width: 32px;
      margin-right: 30px;
      cursor: pointer;
    }
  }
`;

export const StyledA = styled.a`
  display: none;
  @media (max-width: ${padWidth}) {
    display: flex;
    align-items: center;
    padding: 5% 13%;
    opacity: 0.9;
    text-decoration: none;
    color: white;
    font-weight: bold;
    svg {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      fill: white;
    }
    &:hover {
      opacity: 1;
    }
  }
`;
