import React, { useState, useContext } from 'react';
import { ContextStore } from 'store/ContextStore';
import { Element } from 'react-scroll';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { LangSelector } from 'components';
import { animateScroll } from 'react-scroll';

import NavItem from './NavItem';

import { CloseIcon, LaunchIcon, UsermanualIcon, BrochureIcon, EmailIcon, MenuIcon, logo } from 'assets/img';
import { StyledLogo, StyledHeader, StyledNav, StlyedUl, StyledClose, StyledA } from './StyledHeader';

const navOptions = ['overview', 'features', 'applicableModel'];
export default () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { state } = useContext(ContextStore);
  const { scrollY } = state;
  const userManual = () => {
    switch (language) {
      case 'en': {
        return process.env.GATSBY_USER_MANUAL_EN;
      }
      case 'tw': {
        return process.env.GATSBY_USER_MANUAL_TW;
      }
      case 'cn': {
        return process.env.GATSBY_USER_MANUAL_CN;
      }
      default: {
        return process.env.GATSBY_USER_MANUAL_EN;
      }
    }
  };
  const handleSelectBlock = () => {
    setSidebarOpen(false);
  };

  const handleToggle = () => {
    setSidebarOpen((prevState) => !prevState);
  };
  const handleScrollToTop = () => {
    animateScroll.scrollToTop();
  };
  const renderNavOptions = navOptions.map((title, i) => (
    <NavItem key={title} title={title} onClick={handleSelectBlock} text={t(`header.${title}`)} />
  ));
  return (
    <Element name='home'>
      <StyledHeader sidebarOpen={sidebarOpen}>
        <StyledLogo onClick={handleScrollToTop}>
          <img src={logo} alt='' />
        </StyledLogo>
        <MenuIcon onClick={handleToggle} />
        <StyledNav sidebarOpen={sidebarOpen}>
          <StyledClose>
            <CloseIcon onClick={handleToggle} />
          </StyledClose>
          <StlyedUl>
            <NavItem
              title='home'
              onClick={handleSelectBlock}
              text={t(`header.home`)}
              className={scrollY === 0 ? 'isactive' : ''}
            />

            {renderNavOptions}
          </StlyedUl>
          <StyledA href={`${process.env.GATSBY_LAUNCH}`} target='launch'>
            <LaunchIcon />
            <span>{t('header.launch')}</span>
          </StyledA>
          <StyledA href={userManual()} target='userManual'>
            <UsermanualIcon />
            <span>{t('header.userManual')}</span>
          </StyledA>
          <StyledA href={`${process.env.GATSBY_BROCHURE}`} target='brochure'>
            <BrochureIcon />
            <span>{t('header.brochure')}</span>
          </StyledA>
          <StyledA href={`mailto:${process.env.GATSBY_CONTACT_US}`}>
            <EmailIcon />
            <span>{t('header.contactUs')}</span>
          </StyledA>
          <LangSelector setSidebarOpen={setSidebarOpen} />
        </StyledNav>
      </StyledHeader>
    </Element>
  );
};
