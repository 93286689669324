const reducer = (state, action) => {
  switch (action.type) {
    case 'togglePhoneSize': {
      return {
        ...state,
        phoneSize: action.isPhoneSize,
      };
    }
    case 'getScrollY': {
      return {
        ...state,
        scrollY: action.scrollY,
      };
    }
    default: {
      return state;
    }
  }
};
export { reducer };
