import { css, keyframes } from 'styled-components';
const fadeInBottom = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
export const fadeInBottomAnimation = (delayTime) =>
  css`
    ${fadeInBottom} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) ${delayTime} both
  `;

const fadeInXway = (xWay) => keyframes`
  from {
    transform: translateX(${xWay === 'left' ? '-50px' : '50px'});
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
export const fadeInXwayAnimation = (xWay, delayTime) => css`
${fadeInXway(xWay)} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) ${delayTime} both
`;
