import styled from 'styled-components';
import { padWidth } from 'utility/rwd';
import { Element } from 'react-scroll';
import { fadeInBottomAnimation } from 'utility/animation';

export const StyledContainer = styled(Element)`
  padding: 100px 11%;
  background: ${(props) => props.theme.backgroundGary};
  @media (max-width: ${padWidth}) {
    padding: 80px 4%;
  }
`;

export const StyledApplicableModelCotainer = styled.div`
  display: flex;
  justify-content: space-between;
  visibility: ${(props) => (props.scrollToThisElement ? 'visible' : 'hidden')};
  animation: ${(props) => (props.scrollToThisElement ? fadeInBottomAnimation() : 'none')};
  @media (max-width: ${padWidth}) {
    display: block;
  }
`;

export const StyledApplicableModel = styled.div`
  width: 100%;
  text-align: center;
  margin: 170px 1.5% 0 1.5%;
  position: relative;
  > img {
    width: 150px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -254px;
  }
  h6 {
    font-size: 20px;
    font-weight: bold;
    color: ${(props) => props.theme.introductionTitleGary};
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }
  li {
    text-align: left;
    font-size: 18px;
    line-height: 1.5;
    color: ${(props) => props.theme.introductionGary};
    position: relative;
    &::before {
      content: '・';
      position: absolute;
      left: -1em;
      top: 0;
    }
  }
  @media (max-width: ${padWidth}) {
    margin: 0;
    position: static;
    > img {
      transform: none;
      position: static;
      margin-bottom: 30px;
    }
  }
`;
export const StlyedButtonBox = styled.div`
  text-align: left;
  margin-top: 14px;
  padding-left: 1em;
  img {
    width: 8px;
    margin-left: 9px;
  }
  a {
    display: inline-flex;
    align-items: center;
    color: white;
    border-radius: 5px;
    background-color: ${(props) => props.theme.buttonOrange};
    font-size: 18px;
    padding: 7px 13px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
    &:active,
    &:hover {
      background-color: ${(props) => props.theme.orangeHover};
      border: none;
    }
  }
`;
