import { Img4312DD4G, IDS4312D } from 'assets/img';
const ApplicableModelArr = [
  {
    imgSrc: Img4312DD4G,
    title: 'IMG-4312D+-D4G',
    introductionTitle: 'modelOne',
    introduction: ['one', 'two', 'three', 'four', 'five', 'six'],
    href: process.env.GATSBY_IMG4312DD4G_HREF,
  },
  {
    imgSrc: IDS4312D,
    title: 'IDS-4312D+',
    introductionTitle: 'modelTwo',
    introduction: ['one', 'two', 'three', 'four', 'five'],
    href: process.env.GATSBY_IDS4312D_HREF,
  },
];

export default ApplicableModelArr;
